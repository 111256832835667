import React, {createContext, useState} from "react";

interface UserContextProps {
    children: React.ReactNode
}

export const UserContext = createContext<any>({});

export const UserProvider = ({children}: UserContextProps) => {
    const [connected, connexion] = useState<boolean>(false);

    return (
        <UserContext.Provider value={{connected, connexion}}>
            {children}
        </UserContext.Provider>
    )
}