import './Menu.scss'
import {Default} from "../../layers/Default";
import {Title} from "../../components/title/Title";
import {Subtitle} from "../../components/subtitle/Subtitle";
import {Paragraph} from "../../components/paragraph/Paragraph";
import {ColorName} from "../../styles/Color";
import {Menu as MenuComponent} from "../../components/menu/Menu";
import {useEffect, useState} from "react";
import {collection, getDocs, getFirestore} from "firebase/firestore";
import {Menu as MenuType, menuParser} from "../../types/menu/Menu";
import {LinearProgress} from "@mui/material";


export const Menu = () => {
    const [menus, setMenus] = useState<Array<MenuType>>([]);
    const db = getFirestore();


    useEffect(() => {
        if (menus.length === 0) {
            getDocs(collection(db, "Menu")).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setMenus([...menus, menuParser(doc.data())])
                });
            });
        }
    }, [db, menus])

    return (
        <Default>
            {
                menus?.length !== 0 ? menus.map((menu, index) => {
                    return (
                        <MenuComponent key={index}>
                            <Title>{menu.title}</Title>
                            <Subtitle color={ColorName.fir} underline>Entrées</Subtitle>
                            {menu.entree.map((entree, index) => {
                                return (
                                    <Paragraph key={index}>{entree}</Paragraph>
                                )
                            })}

                            <Subtitle color={ColorName.fir} underline>Plats</Subtitle>
                            {menu.main.map((main, index) => {
                                return (
                                    <Paragraph key={index}>{main}</Paragraph>
                                )
                            })}

                            <Subtitle color={ColorName.fir} underline>Desserts</Subtitle>
                            {menu.dessert.map((dessert, index) => {
                                return (
                                    <Paragraph key={index}>{dessert}</Paragraph>
                                )
                            })}
                        </MenuComponent>
                    )
                }) : <MenuComponent>
                    <Title>Chargement en cours</Title>
                    <div className={'progress-bar'}>
                        <LinearProgress color="success"/>
                    </div>
                </MenuComponent>
            }
        </Default>
    )
}
