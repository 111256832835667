import {Default} from '../../layers/Default'
import './About.scss'
import {Contact} from "../../forms/contact/Contact";

export const About = () => {
    return (
        <Default>
            <Contact/>
        </Default>
    )
}