import {Input} from "../../components/input/Input";
import {Default} from "../../layers/Default";
import {Button} from "../../components/button/Button";
import {ColorName} from "../../styles/Color";
import './Connexion.scss'
import {Title} from "../../components/title/Title";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {useContext, useState} from "react";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../contexts/user/UserContext";

export const Connexion = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const auth = getAuth();
    const navigate = useNavigate();
    const {connexion} = useContext(UserContext);

    const handleConnexion = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                toast.success("Connexion réussie");
                connexion(true);
                setTimeout(() => {
                    navigate('/custom-menu');
                }, 1000)
            })
            .catch((error) => {
                toast.error("Email ou mot de passe incorrect");
            });
    }

    return (
        <Default>
            <div className="connexion">
                <Title className={"connexion-title"}>Connexion</Title>
                <form>
                    <div className={"form-connexion"}>
                        <Input
                            label={"Email"}
                            className={"connexion-input"}
                            type={"text"}
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }}/>

                        <Input
                            label={"Mot de passe"}
                            className={"connexion-input"}
                            type={"password"}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}/>

                        <Button
                            className={"connexion-button"}
                            text={"Connexion"}
                            color={ColorName.fir}
                            onClick={handleConnexion}/>
                    </div>
                </form>
            </div>
            <ToastContainer/>
        </Default>
    )
}