export const Color = {
    white: '#FFFFFF',
    black: '#000000',
    fir: '#2C5948',
    lightFir: '#487A6A',
    lightGray: '#5B5B5B',
    gray: '#D0DEE5',
    gold: '#D9CD86',
    lightGold: '#DDD49F',
    transparent: 'transparent',
}

export enum ColorName {
    white= 'white',
    black= 'black',
    fir = 'fir',
    deepFir = 'deepFir',
    lightGray = 'lightGray',
    gray = 'gray',
    gold = 'gold',
    lightGold = 'lightGold',
    transparent = 'transparent',
}

interface RGB {
    r: number
    g: number
    b: number
}

export const getRGB = (color: string): RGB => {
    return {
        r: parseInt(color.slice(1, 3), 16),
        g: parseInt(color.slice(3, 5), 16),
        b: parseInt(color.slice(5, 7), 16),
    }
}