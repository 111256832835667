import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/user/UserContext";
import {Default} from "../../layers/Default";
import {useNavigate} from "react-router-dom";
import {Title} from "../../components/title/Title";
import './CustomMenu.scss';
import {Menu} from "../../types/menu/Menu";
import {getMenus} from "../../services/menu/menu";
import {Menu as MenuComponent} from "../../components/menu/Menu";
import {CustomMenu as CustomMenuComponent} from "../../components/customMenu/CustomMenu";

export const CustomMenu = () => {
    const {connected} = useContext(UserContext);
    const navigate = useNavigate();
    const [menus, setMenus] = useState<Array<Menu>>();

    useEffect(() => {
        if (!connected)
            navigate('/');
    }, [navigate, connected])

    useEffect(() => {
        getMenus().then((menus) => {
            setMenus(menus);
        });
    }, [])

    return (
        <Default>
            <Title>Custom Menu</Title>
            <MenuComponent>
                {
                    menus?.map((menu, index) => (
                        <CustomMenuComponent key={index} menu={menu}/>
                    ))
                }
            </MenuComponent>
        </Default>
    )
}