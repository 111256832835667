import {Menu, menuParser} from "../../types/menu/Menu";
import {collection, doc, getDocs, getFirestore, setDoc} from "firebase/firestore";


export const getMenus = async (): Promise<Array<Menu>> => {

    const db = getFirestore();
    const query = await getDocs(collection(db, "Menu"));

    let menus: Array<Menu> = [];

    query.forEach((doc) => {
        menus?.push(menuParser(doc.data()))
    });

    return menus;
}

export const saveMenu = async (menu: Menu) => {
    const db = getFirestore();
    const menuDoc = doc(db, "Menu", "Menu");

    try {
        await setDoc(menuDoc, menu);
    } catch (e) {
        console.error("Error updating document: ", e);
    }
}