import React from 'react';
import './App.css';
import {Routes} from "./routes/Routes";
import {initializeApp} from "firebase/app";
import {UserProvider} from "./contexts/user/UserContext";

const firebaseConfig = {
    apiKey: "AIzaSyDsZ99Yw046uCxNe5MuPhOkL3asp_OODv4",
    authDomain: "cafe-camille.firebaseapp.com",
    projectId: "cafe-camille",
    storageBucket: "cafe-camille.appspot.com",
    messagingSenderId: "32091861955",
    appId: "1:32091861955:web:3783ad4ef0937caf7b4376"
};

function App() {
    initializeApp(firebaseConfig);


    return (
        <div className="App">
            <UserProvider>
                <Routes>
                    {}
                </Routes>
            </UserProvider>
        </div>
    );
}

export default App;
