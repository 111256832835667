import './Banner.scss'
import {useEffect, useState} from "react";

export const Banner = () => {
    const [repeat, setRepeat] = useState<Array<number>>([]);


    useEffect(() => {
        const number: number = window.screen.width / 34;
        const tab: Array<number> = Array(Math.ceil(number)).fill(0);
        setRepeat(tab)
    }, []);


    return (
        <div className={'banner'}>
            {
                repeat.map(() => (
                    <>
                        <div className={'squares-banner'}>
                            <div className={'full-square square'}></div>
                            <div className={'empty-square square'}></div>
                        </div>

                        <div className={'squares-revers'}>
                            <div className={'full-square square'}></div>
                            <div className={'empty-square square'}></div>
                        </div>
                    </>
                ))
            }
        </div>
    )

}