import React from "react";
import {getRGB} from "../../styles/Color";
import './Title.scss'

interface TitleProps {
    children: React.ReactNode
    color?: string
    shadow?: boolean
    className?: string
}

export const Title = ({children, color, shadow, className}: TitleProps) => {
    const rgb = getRGB(color ?? 'black')
    const textShadow = shadow ? `-1px -1px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)` : 'none'

    return (
        <h1 className={`title ${className}`} style={{
            color: color ?? 'black',
            textShadow: textShadow
        }}>{children}</h1>
    )
}