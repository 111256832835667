import {Title} from "../../components/title/Title";
import './Home.scss'
import {Button} from "../../components/button/Button";
import {Paragraph} from "../../components/paragraph/Paragraph";
import {Color, ColorName} from "../../styles/Color";
import {Default} from "../../layers/Default";
import {useNavigate} from "react-router-dom";
import {Banner} from "../../components/banner/Banner";

export const Home = () => {
    const navigate = useNavigate();
    return (
        <Default>
            <div className={'home'}>
                <div className={'intro-section'}>
                    <Title color={Color.lightGray} shadow>Bienvenue</Title>
                    <Banner />
                    <Paragraph>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                        printer
                        took a galley of type and scrambled it to make a type specimen book. It has survived not only
                        five
                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It
                        was
                        popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                        and
                        more
                        recently with desktop publishing software like Aldus PageMaker including versions of Lorem
                        Ipsum.</Paragraph>
                    <div className={'intro-image-container'}>
                        <img className={'intro-image'} alt={'banner'}
                             src={'./assets/home-banner-image.webp'}/>
                    </div>
                </div>
                <div className={'cards-section'}>
                    <Title className={'cards-intro'} color={Color.gold}>{'Nos cartes'}</Title>
                    <img className={'cards-image'} alt={'meal'} src={'./assets/cards-image.png'}></img>
                    <Paragraph color={Color.gold}>Toute la richesse de produits nobles et frais sur une carte qui
                        revisite
                        les grands classiques de la
                        brasserie, au rythme des saisons.
                        Une cuisine évolutive et simple, à déguster à toute heure : Thon snacké, Filet de bœuf de
                        Salers,
                        Sélection de trois fromages …</Paragraph>
                    <div className={'cards-button'}>
                        <Button text={'Consulter nos cartes'} color={ColorName.gold} className={'cards-button'}
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                    navigate('menu')
                                }}/>
                    </div>
                </div>
                <div className={'contact-section'}>
                    <Title color={Color.fir}>Où nous retrouver ?</Title>

                    <div className={'map-container'}>
                        <a target={'_blank'} rel={"noreferrer"}
                           href={"https://www.google.fr/maps/place/53+Rue+des+Laiti%C3%A8res,+94300+Vincennes/@48.8464825,2.4202913,18z/data=!4m6!3m5!1s0x47e672851a5c959d:0xa64e707f427f5402!8m2!3d48.8465355!4d2.4204669!16s%2Fg%2F11bw3ysscc"}>
                            <img className={'map'} src={'./assets/map2.png'} alt={'map'}/>
                        </a>
                    </div>
                    <div className={'more-information'}>
                        <p className={'address'}><b><u>Adresse :</u></b> 53 Rue des Laitières, 94300 Vincennes</p>
                        <p className={'address'}><b><u>Téléphone :</u></b> XX.XX.XX.XX.XX</p>
                    </div>
                </div>
            </div>
        </Default>
    )
}