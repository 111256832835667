import React from "react";

interface MenuProps {
    children: React.ReactNode;
}

export const Menu = ({children}: MenuProps) => {
    return (
        <div className={'card'}>
            <div className={'card-border'}>
                <img src={'./assets/leaf.png'} alt={'leaf'} className={'leaf-image leaf-image-top'}/>
                <div className={'card-border-v'}/>
                <div className={'anti-leaf'}/>
            </div>
            <div className={'card-content'}>
                <div className={'card-border'}>
                    <div className={'card-border-h'}/>
                </div>
                <div className={'card-text'}>
                    {children}
                </div>
                <div className={'card-border'}>
                    <div className={'card-border-h'}/>
                </div>
            </div>
            <div className={'card-border'}>
                <div className={'anti-leaf'}/>
                <div className={'card-border-v'}/>
                <img src={'./assets/leaf.png'} alt={'leaf'} className={'leaf-image leaf-image-bottom'}/>
            </div>
        </div>
    )
}