import {Title} from "../title/Title";
import {Subtitle} from "../subtitle/Subtitle";
import {ColorName} from "../../styles/Color";
import {Input} from "../input/Input";
import {Button} from "../button/Button";
import {Menu} from "../../types/menu/Menu";
import {useState} from "react";
import {saveMenu as saveMenuItem} from "../../services/menu/menu";
import {toast} from "react-toastify";

interface CustomMenuProps {
    menu: Menu
}

export const CustomMenu = ({menu}: CustomMenuProps) => {
    const [currentMenu, setCurrentMenu] = useState<Menu>(menu);

    function removeItem(type: 'entree' | 'main' | 'dessert', index: number) {
        return () => {
            if (currentMenu) {
                const newMenu = {...currentMenu};
                newMenu[type].splice(index, 1);
                setCurrentMenu(newMenu);
            }
        }
    }

    function addItem(type: 'entree' | 'main' | 'dessert', index: number) {
        if (currentMenu) {
            const newMenu = {...currentMenu};
            newMenu[type].push('Nouveau');
            setCurrentMenu(newMenu);
        }
    }

    function handleChangeItem(type: string, index: number, event: any) {
        if (currentMenu) {
            let newMenu: any = {...currentMenu};
            newMenu[type][index] = event.target.value;
            setCurrentMenu(newMenu);
        }
    }

    const saveMenu = async () => {
        try {
            await saveMenuItem(currentMenu);
            toast.success('Menu sauvegardé');
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <Title>{currentMenu.title ?? 'Menu'}</Title>
            <Subtitle color={ColorName.fir} underline>Entrées</Subtitle>
            <div className={'inputs'}>
                {currentMenu?.entree?.map((entree, index) => (
                    <div className={'input-content'} key={index}>
                        <Input label={'entrée'} className={'menu-input'} defaultValue={entree}
                               onChange={(event) => {
                                   handleChangeItem('entree', index, event)
                               }}/>
                        <div>
                            <Button text={'-'} color={ColorName.fir} onClick={removeItem('entree', index)}/>
                        </div>
                    </div>
                ))}
                <Button text={'+'} color={ColorName.fir} onClick={() => {
                    addItem('entree', (currentMenu?.entree?.length ?? 0) + 1)
                }}/>
            </div>

            <Subtitle color={ColorName.fir} underline>Plats</Subtitle>
            <div className={'inputs'}>
                {currentMenu?.main?.map((main, index) => (
                    <div className={'input-content'} key={index}>
                        <Input label={'plat'} className={'menu-input'} defaultValue={main}
                               onChange={(event) => {
                                   handleChangeItem('main', index, event)
                               }}/>
                        <div>
                            <Button text={'-'} color={ColorName.fir} onClick={removeItem('main', index)}/>
                        </div>
                    </div>
                ))}
                <Button text={'+'} color={ColorName.fir} onClick={() => {
                    addItem('main', (currentMenu?.main?.length ?? 0) + 1)
                }}/>
            </div>

            <Subtitle color={ColorName.fir} underline>Desserts</Subtitle>
            <div className={'inputs'}>
                {currentMenu?.dessert?.map((dessert, index) => (
                    <div className={'input-content'} key={index}>
                        <Input label={'dessert'} className={'menu-input'} defaultValue={dessert}
                               onChange={(event) => {
                                   handleChangeItem('dessert', index, event)
                               }}/>
                        <div>
                            <Button text={'-'} color={ColorName.fir} onClick={removeItem('dessert', index)}/>
                        </div>
                    </div>
                ))}
                <Button text={'+'} color={ColorName.fir} onClick={() => {
                    addItem('dessert', (currentMenu?.dessert?.length ?? 0) + 1)
                }}/>
            </div>

            <div className={'button-save-menu'}>
                <Button text={'Enregistrer'} color={ColorName.fir} onClick={saveMenu}
                        className={'save-button'}/>
            </div>
        </>
    )
}