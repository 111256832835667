import ButtonMui from '@mui/material/Button';
import './Button.scss'
import {ColorName} from "../../styles/Color";

interface ButtonProps {
    text: string
    className?: string
    color: ColorName
    onClick?: () => void
}

export const Button = ({text, color, className, onClick}: ButtonProps) => {
    return (
        <ButtonMui variant="outlined" className={`${className} button button-${color}`} onClick={onClick}>{text}</ButtonMui>
    )
}