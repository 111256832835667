import './Paragraph.scss'
import React from "react";

interface ParagraphProps {
    children: React.ReactNode
    className?: string
    color?: string
}

export const Paragraph = ({children, color, className}: ParagraphProps) => {
    return (
        <p className={`paragraph ${className}`} style={{color: color ?? 'black'}}>{children}</p>
    )
}