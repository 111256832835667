import {TextField} from "@mui/material";
import './Input.scss'
import React from "react";

interface InputProps {
    label: string
    className?: string
    type?: React.HTMLInputTypeAttribute | undefined
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    defaultValue?: string
}

export const Input = ({label, className, type, onChange, defaultValue}: InputProps) => {
    return (
        <div className={`${className} input`}>
            <TextField
                type={type}
                className={`${className} input-material`}
                label={label} variant="filled"
                onChange={onChange}
                defaultValue={defaultValue}
            />
        </div>
    )
}