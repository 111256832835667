import './Subtitle.scss'
import React from "react";

interface SubtitleProps {
    children: React.ReactNode
    color?: string
    underline?: boolean
    className?: string
}

export const Subtitle = ({children, color, underline, className}: SubtitleProps) => {
    const finalColor = color ?? 'black'

    return (
        <h3 className={`subtitle ${className} ${underline ? `subtitle-underline-${finalColor}`:''} subtitle-${finalColor}`} >{children}</h3>
    )
}