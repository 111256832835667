import React from "react";
import './Default.scss';
import {Paragraph} from "../components/paragraph/Paragraph";
import {useNavigate} from "react-router-dom";

interface DefaultProps {
    children: React.ReactNode
}

export const Default = ({children}: DefaultProps) => {
    const navigate = useNavigate();

    const road = (path: string) => {
        navigate(path);
    }

    const [activeMenu, setMenu] = React.useState(false);

    return (
        <>
            <div className={'menu'}>
                <div className={'menu-burger'} onClick={() => {
                    setMenu(!activeMenu)
                }}>
                    <div/>
                    <div/>
                    <div/>
                </div>
                <p className={'menu-title disable-text'} onClick={() => road('/')}>
                    Café Camille
                </p>
                <img className={'menu-icon'} src={'./icon/iconCamille.png'} alt={'icon_camille'}
                     onClick={() => road('/')}/>
            </div>
            <div className={`navigation-menu`}>
                <div className={`navigation ${activeMenu ? 'navigation-show' : 'navigation-hidden'}`}>
                    <h1 className={'disable-text'} onClick={() => {
                        window.scrollTo(0, 0);
                        road('/')
                    }}>Home</h1>
                    <h1 className={'disable-text'} onClick={() => {
                        window.scrollTo(0, 0);
                        road('/menu')
                    }}>Menus</h1>
                    <h1 className={'disable-text'} onClick={() => {
                        window.scrollTo(0, 0);
                        road('/about')
                    }}>Contact</h1>
                </div>
                {/*<div className={'bottom-navigation'}/>*/}
            </div>
            {children}
            <div className={'footer'}>
                <Paragraph className={'footer-text'}>Mentions légales - contact :
                    contactcafecamille@gmail.com</Paragraph>
            </div>
        </>
    )
}