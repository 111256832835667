export interface Menu {
    title: string | 'Menu';
    entree: string[];
    main: string[];
    dessert: string[];
}

export const menuParser = (menu: any) => {
    return {
        title: menu.title,
        entree: menu.entree,
        main: menu.main,
        dessert: menu.dessert
    }
}