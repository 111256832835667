import {Title} from "../../components/title/Title";
import {Color, ColorName} from "../../styles/Color";
import {Input} from "../../components/input/Input";
import './Contact.scss'
import {Button} from "../../components/button/Button";
import React from "react";

export const Contact = () => {
    const [email] = React.useState('cafe.camille.pro@gmail.com')
    const [body, setBody] = React.useState('')
    const [name, setName] = React.useState('')
    const [firstName, setFirstName] = React.useState('')

    return (
        <div className={'contact-form'}>
            <form>
                <Title color={Color.fir}>Contactez-nous</Title>
                <div className={'form-input'}>
                    <Input label={'Nom'} onChange={(event) => {
                        setName(event.target.value)
                    }}/>
                    <Input label={'Prénom'} onChange={(event) => {
                        setFirstName(event.target.value)
                    }}/>
                    <Input label={'Message'} className={'message-input'} onChange={(event) => {
                        setBody(event.target.value)
                    }}/>
                    <a href={`mailto:${email}?subject=${name + ' ' + firstName}&body=${body}`}>
                        <Button text={'Envoyer'} color={ColorName.fir} className={'form-button'}/>
                    </a>
                </div>
            </form>
        </div>
    )
}