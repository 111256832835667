import {Home} from "../pages/home/Home";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";
import {About} from "../pages/about/About";
import {Menu} from "../pages/menu/Menu";
import {Connexion} from "../pages/connexion/Connexion";
import {CustomMenu} from "../pages/customMenu/CustomMenu";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home/>,
    },
    {
        path: '/about',
        element: <About/>,
    },
    {
        path: '/menu',
        element: <Menu/>,
    },
    {
        path: '/connexion',
        element: <Connexion/>,
    },
    {
        path: '/custom-menu',
        element: <CustomMenu/>,
    }
])

interface routesProps {
    children: React.ReactNode
}

export const Routes = ({children}: routesProps) => {
    return (
        <>
            <RouterProvider router={router}/>
            {children}
        </>
    )
}